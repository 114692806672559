<template>
	<div class="container">
		<div class="row justify-content-start align-content-center">
			<div class="col-md-10 col-sm-10 col-xs-7 col-lg-6 mt-5">
				<router-link to="/partners/list" class="btn btn-light mb-4 btn-sm">
					Go back
				</router-link>
				<div class="card">
					<div class="card-body">
						<template>
							<div class="mt-3">
								<h2 class="mb-1">Create a new partner</h2>
							</div>
							<small>Kindly input the correct informations</small>
							<div class="form-group" v-if="errorMessage">
								<div class="alert alert-danger">
									<span>{{ errorMessage }}</span>
								</div>
							</div>
							<div
								class="
                  d-flex
                  justify-content-between
                  align-items-center
                  p-3
                  rounded-lg
                "
								style="background-color: #f9fbfd"
								>
								<div>
									<input
										v-model="selectedUserStatus"
										@change="handleUserCheckbox($event)"
										type="radio"
										class="mr-1"
										id="existingUser"
										value="existingUser"
										:checked="selectedUserStatus === 'existingUser'"
										name="entry"
										/>
									<label for="existingUser">Existing User</label>
								</div>
								<div>
									<input
										@change="handleUserCheckbox($event)"
										v-model="selectedUserStatus"
										type="radio"
										class="mr-1"
										id="newUser"
										value="newUser"
										:checked="selectedUserStatus === 'newUser'"
										name="entry"
										/>
									<label for="newUser">New User</label>
								</div>
							</div>
							<form
								@submit.prevent="handleSubmission"
								class="mt-4"
								v-if="currentUserComponent === 'existingUser'"
								>
								<div class="form-group mt-4">
									<label for="fname">Select user</label>
									<multiselect
										v-model="form.selectedUser"
										id="ajax"
										:custom-label="shuttlersUser"
										track-by="id"
										placeholder="Select user to profile as partner"
										open-direction="bottom"
										:options="users"
										:multiple="false"
										:searchable="true"
										:loading="isLoading"
										:internal-search="false"
										:clear-on-select="true"
										:close-on-select="true"
										:preserve-search="true"
										:options-limit="20"
										:show-no-results="false"
										:hide-selected="false"
										@search-change="asyncFindUsers"
										>
										<template slot="singleLabel" slot-scope="props">
											<span class="option__fname"
												>{{ props.option.fname }}
											</span>
											<span class="option__lname">
												{{ props.option.lname }}</span
												>
										</template>

										<template slot="option" slot-scope="props">
											<p class="py-0 my-0">
												<span class="option__fname"
													>{{ props.option.fname }}
												</span>
												<span class="option__lname">
													{{ props.option.lname }}</span
													>
											</p>
											<p class="option__email py-0 my-0">
												{{ props.option.email }}
											</p>
										</template>

										<span slot="noResult"
											>Oops! No user found. Consider changing the search
											query.</span
											>
									</multiselect>
								</div>

								<p>Select Category</p>
								<div
									class="
                    d-flex
                    justify-content-between
                    align-items-center
                    p-3
                    rounded-lg
                  "
									style="background-color: #f9fbfd"
									>
									<div>
										<input
											v-model="selected"
											@change="handleCheckbox($event)"
											type="radio"
											class="mr-1"
											id="individual"
											value="individual"
											:checked="selected === 'individual'"
											name="entry"
											/>
										<label for="individual">An Individual</label>
									</div>

									<div>
										<input
											@change="handleCheckbox($event)"
											v-model="selected"
											type="radio"
											class="mr-1"
											id="business"
											value="business"
											:checked="selected === 'business'"
											name="entry"
											/>
										<label for="business">Registered Business</label>
									</div>
								</div>

								<div v-if="currentComponent === 'business'">
									<p class="text-muted">Company information</p>
									<div class="form-group mt-4">
										<label for="companyName">Company name</label>
										<input
											v-model.trim="$v.form.companyName.$model"
											:class="{
												'is-invalid':
													$v.form.companyName.$dirty &&
													$v.form.companyName.$error,
											}"
											id="companyName"
											name="companyName"
											type="text"
											placeholder="Enter company name"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.companyName.validName &&
													$v.form.companyName.$error
											"
											>
											Company name is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyEmail">Company email</label>
										<input
											v-model.trim="$v.form.companyEmail.$model"
											:class="{
												'is-invalid':
													$v.form.companyEmail.$dirty &&
													$v.form.companyEmail.$error,
											}"
											id="companyEmail"
											name="companyEmail"
											type="email"
											placeholder="Enter company email"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.companyEmail.validName &&
													$v.form.companyEmail.$error
											"
											>
											Company email is required.
										</span>
									</div>

									<div class="form-group pt-3">
										<label for="phone" class="mb-1">Company phone number</label>
										<div class="input-group mb-3 countriesParent">
											<div class="countriesChild">
												<select class="custom-select" v-model="form.country">
													<option
														:key="country.code"
														v-for="country in countries"
														v-bind:value="country"
														>
														{{ countryCodeToEmoji(country.code) }}
														{{ country.phone_code }}
													</option>
												</select>
											</div>
											<input
												type="tel"
												class="form-control"
												v-model.trim="$v.form.phone.$model"
												:class="
													$v.form.phone.$dirty &&
														($v.form.phone.$error || !isPhoneValid)
														? 'ring-red-500'
														: 'ring-sh-grey-300'
												"
												name="phone"
												id="phone"
												/>
										</div>
										<div
											v-if="
												$v.form.phone.$dirty &&
													($v.form.phone.$error || !isPhoneValid)
											"
											class="text-small text-danger font-light"
											>
											Enter company phone number
										</div>
									</div>

									<div class="form-group mt-4">
										<label for="companyRC">Company RC number</label>
										<input
											v-model.trim="$v.form.companyRC.$model"
											:class="{
												'is-invalid':
													$v.form.companyRC.$dirty && $v.form.companyRC.$error,
											}"
											id="companyRC"
											name="companyRC"
											type="tel"
											placeholder="Enter company RC number"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.companyRC.validName && $v.form.companyRC.$error
											"
											>
											Company RC number is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyRC">Business Type</label>
										<select
											v-model.trim="$v.form.business_type.$model"
											:class="{
												'is-invalid':
													$v.form.business_type.$dirty &&
													$v.form.business_type.$error,
											}"
											class="form-control"
											>
											<option disabled="" selected="">Select Option</option>
											<option
												:value="option"
												v-for="(option, index) in businessOptions"
												:key="index"
												>
												{{ option }}
											</option>
										</select>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.business_type.validName &&
													$v.form.business_type.$error
											"
											>
											Company business type is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyAddress">Company Address</label>
										<input
											v-model.trim="$v.form.company_address.$model"
											:class="{
												'is-invalid':
													$v.form.company_address.$dirty &&
													$v.form.company_address.$error,
											}"
											id="companyAddress"
											name="companyAddress"
											type="tel"
											placeholder="Enter company address"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.company_address.validName &&
													$v.form.company_address.$error
											"
											>
											Company address is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyAddress">Years of exprience</label>
										<div style="width: 100%">
											<v-datepicker
												v-model="form.experience_start_date"
												style="width: 100%"
												></v-datepicker>
										</div>
									</div>
								</div>

								<p class="text-muted mt-3">City Selection</p>
								<div class="flex flex-col space-y-2">
									<label class="text-grays-black-5 font-medium text-sm p-1"
										>Cities</label
										>
									<v-select
										class="form-group"
										:options="cities"
										:reduce="(city) => city.id"
										v-model="city_ids"
										label="name"
										multiple
										required
										placeholder="search city"
										>
									</v-select>
								</div>

								<div class="d-flex justify-content-end align-items-end">
									<button
										v-if="currentComponent === 'individual'"
										class="
                      rounded-lg
                      px-4
                      py-3
                      font-weight-medium
                      outline-0
                      border-0
                      text-sm
                    "
										:disabled="!isIndividualFormComplete && processing"
										:style="[
											isIndividualFormComplete && !processing
												? { backgroundColor: '#20E682' }
												: { backgroundColor: '#EFF2F7' },
										]"
										>
										{{
											processing && isIndividualFormComplete
												? 'Saving...'
												: 'Create'
										}}
										<img src="@/assets/img/arrow.svg" />
									</button>
									<button
										v-if="currentComponent === 'business'"
										class="
                      rounded-lg
                      px-4
                      py-3
                      font-weight-medium
                      outline-0
                      border-0
                      text-sm
                    "
										:disabled="!isBusinessFormComplete && processing"
										:style="[
											isBusinessFormComplete && !processing
												? { backgroundColor: '#20E682' }
												: { backgroundColor: '#EFF2F7' },
										]"
										>
										{{
											processing && isBusinessFormComplete
												? 'Saving...'
												: 'Create'
										}}<img class="ml-2" src="@/assets/img/arrow.svg" />
									</button>
								</div>
							</form>

							<form
								@submit.prevent="manageUser"
								class="mt-4"
								v-if="currentUserComponent === 'newUser'"
								>
								<div class="form-group mt-4">
									<label for="fname">First Name</label>
									<input
										v-model.trim="$v.user.fname.$model"
										:class="{
											'is-invalid':
												$v.user.fname.$dirty && $v.user.fname.$error,
										}"
										id="fname"
										name="fname"
										type="text"
										placeholder="Enter your first name"
										class="form-control"
										/>
									<span
										class="text-xs text-danger"
										v-if="!$v.user.fname.validName && $v.user.fname.$error"
										>
										First Name is required.
									</span>
								</div>

								<div class="form-group mt-4">
									<label for="lname">Last Name</label>
									<input
										v-model.trim="$v.user.lname.$model"
										:class="{
											'is-invalid':
												$v.user.lname.$dirty && $v.user.lname.$error,
										}"
										id="lname"
										name="lname"
										type="text"
										placeholder="Enter your last name"
										class="form-control"
										/>
									<span
										class="text-xs text-danger"
										v-if="!$v.user.lname.validName && $v.user.lname.$error"
										>
										Last Name is required.
									</span>
								</div>

								<div class="form-group mt-4">
									<label for="username">Email Address</label>
									<input
										v-model.trim="$v.user.email.$model"
										:class="{
											'is-invalid':
												$v.user.email.$dirty && $v.user.email.$error,
										}"
										id="email"
										name="email"
										type="email"
										placeholder="Enter your email address"
										class="form-control"
										/>
									<span
										class="text-xs text-danger"
										v-if="!$v.user.email.validName && $v.user.email.$error"
										>
										Email is required.
									</span>
								</div>

								<div class="form-group pt-3">
									<label for="phone" class="mb-1">Phone Number</label>
									<div class="input-group mb-3 countriesParent">
										<div class="countriesChild">
											<select class="custom-select" v-model="form.country">
												<option
													:key="country.code"
													v-for="country in countries"
													v-bind:value="country"
													>
													{{ countryCodeToEmoji(country.code) }}
													{{ country.phone_code }}
												</option>
											</select>
										</div>
										<input
											type="tel"
											class="form-control"
											v-model.trim="$v.user.phone.$model"
											:class="
												$v.user.phone.$dirty &&
													($v.user.phone.$error || !isUserPhoneValid)
													? 'ring-red-500'
													: 'ring-sh-grey-300'
											"
											name="phone"
											id="phone"
											/>
									</div>
									<div
										v-if="
											$v.user.phone.$dirty &&
												($v.user.phone.$error || !isUserPhoneValid)
										"
										class="text-small text-danger font-light"
										>
										Enter a valid phone number
									</div>
								</div>

								<div class="form-group mt-4">
									<label for="username">Password</label>
									<input
										v-model.trim="$v.user.password.$model"
										:class="{
											'is-invalid':
												$v.user.password.$dirty && $v.user.password.$error,
										}"
										id="password"
										name="password"
										type="password"
										placeholder="Enter your password"
										class="form-control"
										/>
									<span
										class="text-xs text-danger"
										v-if="
											!$v.user.password.validName && $v.user.password.$error
										"
										>
										Password is required.
									</span>
								</div>

								<div>
									<label class="form-label">Company (optional)</label>
									<v-select
										v-model="user.corporate_id"
										class="form-group"
										:options="companies"
										label="corporate_name"
										:reduce="(company) => company.id"
										>
									</v-select>
								</div>

								<div class="form-group">
									<label class="form-label">Date of Birth</label>
									<div style="width: 100%">
										<v-datepicker
											v-model="user.dob"
											value-type="YYYY-MM-DD"
											format="DD MMMM, YYYY"
											style="width: 100%"
											></v-datepicker>
									</div>
								</div>
								<div class="row">
									<div class="col-12">
										<div class="form-group d-inline-flex">
											<div class="form-radio ml-2">
												<input
													v-model="user.gender"
													class="form-radio-input"
													type="radio"
													id="male"
													value="male"
													required
													/>
												<label for="male" class="form-radio-label ml-2"
													>Male</label
													>
											</div>
											<div class="form-radio ml-4">
												<input
													v-model="user.gender"
													class="form-radio-input"
													type="radio"
													id="female"
													value="female"
													required
													/>
												<label for="female" class="form-radio-label ml-2"
													>Female</label
													>
											</div>
										</div>
									</div>
								</div>

								<p>Select Category</p>
								<div
									class="
                    d-flex
                    justify-content-between
                    align-items-center
                    p-3
                    rounded-lg
                  "
									style="background-color: #f9fbfd"
									>
									<div>
										<input
											v-model="selected"
											@change="handleCheckbox($event)"
											type="radio"
											class="mr-1"
											id="individual"
											value="individual"
											:checked="selected === 'individual'"
											name="entry"
											/>
										<label for="individual">An Individual</label>
									</div>

									<div>
										<input
											@change="handleCheckbox($event)"
											v-model="selected"
											type="radio"
											class="mr-1"
											id="business"
											value="business"
											:checked="selected === 'business'"
											name="entry"
											/>
										<label for="business">Registered Business</label>
									</div>
								</div>

								<div v-if="currentComponent === 'business'">
									<p class="text-muted">Company information</p>
									<div class="form-group mt-4">
										<label for="companyName">Company name</label>
										<input
											v-model.trim="$v.form.companyName.$model"
											:class="{
												'is-invalid':
													$v.form.companyName.$dirty &&
													$v.form.companyName.$error,
											}"
											id="companyName"
											name="companyName"
											type="text"
											placeholder="Enter company name"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.companyName.validName &&
													$v.form.companyName.$error
											"
											>
											Company name is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyEmail">Company email</label>
										<input
											v-model.trim="$v.form.companyEmail.$model"
											:class="{
												'is-invalid':
													$v.form.companyEmail.$dirty &&
													$v.form.companyEmail.$error,
											}"
											id="companyEmail"
											name="companyEmail"
											type="email"
											placeholder="Enter company email"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.companyEmail.validName &&
													$v.form.companyEmail.$error
											"
											>
											Company email is required.
										</span>
									</div>

									<div class="form-group pt-3">
										<label for="phone" class="mb-1">Company phone number</label>
										<div class="input-group mb-3 countriesParent">
											<div class="countriesChild">
												<select class="custom-select" v-model="form.country">
													<option
														:key="country.code"
														v-for="country in countries"
														v-bind:value="country"
														>
														{{ countryCodeToEmoji(country.code) }}
														{{ country.phone_code }}
													</option>
												</select>
											</div>
											<input
												type="tel"
												class="form-control"
												v-model.trim="$v.form.phone.$model"
												:class="
													$v.form.phone.$dirty &&
														($v.form.phone.$error || !isPhoneValid)
														? 'ring-red-500'
														: 'ring-sh-grey-300'
												"
												name="phone"
												id="phone"
												/>
										</div>
										<div
											v-if="
												$v.form.phone.$dirty &&
													($v.form.phone.$error || !isPhoneValid)
											"
											class="text-small text-danger font-light"
											>
											Enter a valid phone number
										</div>
									</div>

									<div class="form-group mt-4">
										<label for="companyRC">Company RC number</label>
										<input
											v-model.trim="$v.form.companyRC.$model"
											:class="{
												'is-invalid':
													$v.form.companyRC.$dirty && $v.form.companyRC.$error,
											}"
											id="companyRC"
											name="companyRC"
											type="tel"
											placeholder="Enter company RC number"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.companyRC.validName && $v.form.companyRC.$error
											"
											>
											Company RC number is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyRC">Business Type</label>
										<select
											v-model.trim="$v.form.business_type.$model"
											:class="{
												'is-invalid':
													$v.form.business_type.$dirty &&
													$v.form.business_type.$error,
											}"
											class="form-control"
											>
											<option disabled="" selected="">Select Option</option>
											<option
												:value="option"
												v-for="(option, index) in businessOptions"
												:key="index"
												>
												{{ option }}
											</option>
										</select>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.business_type.validName &&
													$v.form.business_type.$error
											"
											>
											Company business type is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyAddress">Company Address</label>
										<input
											v-model.trim="$v.form.company_address.$model"
											:class="{
												'is-invalid':
													$v.form.company_address.$dirty &&
													$v.form.company_address.$error,
											}"
											id="companyAddress"
											name="companyAddress"
											type="tel"
											placeholder="Enter company address"
											class="form-control"
											/>
										<span
											class="text-xs text-danger"
											v-if="
												!$v.form.company_address.validName &&
													$v.form.company_address.$error
											"
											>
											Company address is required.
										</span>
									</div>

									<div class="form-group mt-4">
										<label for="companyAddress">Years of exprience</label>
										<div style="width: 100%">
											<v-datepicker
												v-model="form.experience_start_date"
												style="width: 100%"
												></v-datepicker>
										</div>
									</div>
								</div>

								<p class="text-muted mt-3">City Selection</p>
								<div class="flex flex-col space-y-2">
									<label class="text-grays-black-5 font-medium text-sm p-1"
										>Cities</label
										>
									<v-select
										class="form-group"
										:options="cities"
										:reduce="(city) => city.id"
										v-model="city_ids"
										label="name"
										multiple
										required
										placeholder="search city"
										>
									</v-select>
								</div>

								<div class="d-flex justify-content-end align-items-end">
									<button
										class="
                      rounded-lg
                      px-4
                      py-3
                      font-weight-medium
                      outline-0
                      border-0
                      text-sm
                    "
										type="submit"
										:disabled="processing"
										:style="[
											processing
												? { backgroundColor: '#EFF2F7' }
												: { backgroundColor: '#20E682' },
										]"
										>
										{{ processing ? 'Saving...' : 'Create' }}
										<img src="@/assets/img/arrow.svg" />
									</button>
								</div>
							</form>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import countryCodeEmoji from 'country-code-emoji'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'
import { extractErrorMessage } from '@/utils/helpers'
import Multiselect from 'vue-multiselect'

export default {
  data () {
    return {
      errorMessage: '',
      showPassword: false,
      selectedCities: [],
      selectedIndex: -1,
      cities: [],
      loadingData: false,
      city_ids: [],
      notifyPartner: false,
      perPage: 20,
      currentPage: 1,
      newUserId: '',
      businessOptions: [
        'Business Name',
        'Company',
        'Incorporated Trustee',
        'Limited Partnership',
        'Limited Liability Partnership'
      ],
      form: {
        companyName: '',
        companyEmail: '',
        phone: '',
        companyRC: '',
        country: '',
        selectedUser: '',
        business_type: '',
        company_address: '',
        experience_start_date: ''
      },

      user: {
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: '',
        corporate_id: '',
        dob: '',
        gender: 'male'
      },
      companies: [],
      processing: false,
      selected: '',
      selectedUserStatus: '',
      debounce: null,
      loading: false,
      countries: [],
      isLoading: false,
      users: [],
      isPhoneValid: false,
      isUserPhoneValid: false,
      currentComponent: 'individual',
      currentUserComponent: 'newUser'
    }
  },
  mixins: [validationMixin],
  components: {
    Multiselect
  },
  validations: {
    form: {
      companyName: {
        required
      },
      companyEmail: {
        required,
        email
      },
      phone: {
        required
      },
      companyRC: {
        required
      },
      selectedUser: {
        required
      },
      business_type: {
        required
      },
      company_address: {
        required
      },
      experience_start_date: {
        required
      }
    },

    user: {
      fname: {
        required
      },
      lname: {
        required
      },
      phone: {
        required
      },
      email: {
        required
      },
      password: {
        required
      },
      corporate_id: {
        required
      },
      dob: {
        required
      },
      gender: 'male'
    }
  },
  created () {
    this.fetchCountries()
    this.fetchUsers()
    this.fetchCities()
    this.fetchCompanies()
  },
  computed: {
    isIndividualFormComplete () {
      return !!(this.currentComponent === 'individual' &&
        this.form.selectedUser !== '' &&
        this.city_ids.length)
    },
    isBusinessFormComplete () {
      return !!(this.currentComponent === 'business' &&
        this.form.selectedUser !== '' &&
        this.city_ids.length &&
        this.form.companyName !== '' &&
        this.form.companyEmail !== '' &&
        this.form.phone !== '' &&
        this.form.companyRC !== '' &&
        this.form.business_type !== '' &&
        this.form.company_address !== '' &&
        this.form.experience_start_date !== '')
    }
  },
  mounted () {
    this.currentComponent = 'individual'
    this.selected = 'individual'

    this.currentUserComponent = 'existingUser'
    this.selectedUserStatus = 'existingUser'
  },
  methods: {
    fetchCompanies () {
      this.axios.get('/v1/corporates?limit=100000').then((res) => {
        this.companies = res.data.data
      })
    },
    manageUser () {
      this.processing = true
      this.errorProcessing = false

      this.errorMessage = ''

      this.axios({
        method: 'POST',
        url: '/v1/users/',
        data: {
          fname: this.user.fname,
          lname: this.user.lname,
          phone: this.user.phone,
          email: this.user.email,
          password: this.user.password,
          gender: this.user.gender,
          dob: this.user.dob,
          corporate_id: this.user.corporate_id || null // Since the company field is optional, we set 'corporate_id' to null if no company was selected
        }
      })
        .then((res) => {
          if (this.selected === 'individual' || this.selected === 'business') {
            return this.handleSubmission(res?.data?.id)
          }
        })
        .catch((e) => {
          this.errorProcessing = true
          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.$toastr.e(msg, 'Error')

          // this.$swal({
          //   icon: 'error',
          //   text: msg,
          //   showCloseButton: true,
          // })
        })
        .finally(() => (this.processing = false))
    },
    shuttlersUser ({ fname, lname }) {
      return `${fname} ${lname}`
    },
    resetIndividualForm () {
      this.cities.pop()
      this.form.selectedUser = null
    },
    resetBusinessForm () {
      this.cities.pop()
      this.form.selectedUser = null
      this.form.companyName = ''
      this.form.companyEmail = ''
      this.form.phone = ''
      this.form.companyRC = ''
      ;(this.form.business_type = ''),
      (this.form.company_address = ''),
      (this.form.experience_start_date = '')
    },
    handleCheckbox (e) {
      if (e.target.id === 'individual') {
        this.currentComponent = 'individual'
        this.resetBusinessForm()
      } else {
        this.currentComponent = 'business'
        this.resetIndividualForm()
      }
    },

    handleUserCheckbox (e) {
      if (e.target.id === 'newUser') {
        this.currentUserComponent = 'newUser'
        // this.resetBusinessForm()
      } else {
        this.currentUserComponent = 'existingUser'
        // this.resetIndividualForm()
      }
    },

    async addCitySelection (partnerId) {
      this.processing = true

      this.city_ids.map((cityId) => {
        this.axios
          .post(`/v1/partners/${partnerId}/cities`, {
            city_id: cityId
          })
          .then((res) => {
            
          })
          .catch((err) => {
            this.$toastr.e(err?.response?.data?.message, 'Error')
            this.processing = false
          })
      })
    },

    async handleSubmission (userId) {
      this.processing = true

      const individualEntryPayload = {
        mode: 'individual',
        partner_user_id: this.form.selectedUser?.id
          ? this.form.selectedUser?.id
          : userId
      }

      const businessEntryPayload = {
        company_name: this.form.companyName,
        company_email: this.form.companyEmail,
        company_phone: this.form.phone,
        rc_number: this.form.companyRC,
        country: this.form.country,
        business_type: this.form.business_type,
        company_address: this.form.company_address,
        years_of_experience: this.form.experience_start_date,
        mode: 'business',
        partner_user_id: this.form.selectedUser?.id
          ? this.form.selectedUser?.id
          : userId
      }

      if (this.selected === 'individual') {
        try {
          const result = await this.axios.post(
            '/v1/partners',
            individualEntryPayload
          )

          await this.addCitySelection(result.data.id)
          this.$toastr.s('partner was successfully created ', 'Success')
          this.$router.push({
            name: 'PartnerDetails',
            params: {
              partnerId: result.data.id,
              accountSid: result.data.account_sid
            }
          })
        } catch (err) {
          this.$toastr.e(err?.response?.data?.message, 'Error')
          this.processing = false
        }
      }

      if (this.selected === 'business') {
        try {
          const response = await this.axios.post(
            '/v1/partners',
            businessEntryPayload
          )
          await this.addCitySelection(response.data.id)
          this.$toastr.s('Partner was successfully created', 'Success')
          this.$router.push({
            name: 'PartnerDetails',
            params: {
              vehicleCount: 3,
              partnerId: response.data.id,
              accountSid: response.data.account_sid
            }
          })
        } catch (err) {
          this.$toastr.e(err?.response?.data?.message, 'Error')
          this.processing = false
        }
      }
    },
    fetchCities () {
      this.loadingData = true
      this.$axios
        .get('/v1/city/all?limit=1000&fields=id,name,code,country_id')
        .then(async (res) => {
          this.cities = res.data.data
        })
        .finally(() => {
          this.loadingData = false
        })
    },
    removeCity (index) {
      this.selectedCities.splice(index, 1)
    },

    countryCodeToEmoji (code) {
      return countryCodeEmoji(code)
    },
    async fetchCountries () {
      const response = await this.axios.get('/v1/countries')
      this.countries = response.data
      this.form.country = this.countries[0]
    },
    validatePhoneNumber () {
      this.isPhoneValid = isValidPhoneNumber(
        this.form.phone,
        this.form.country.code
      )
    },

    validateUserPhoneNumber () {
      this.isUserPhoneValid = isValidPhoneNumber(
        this.user.phone,
        this.form.country.code
      )
    },
    toggleShowPassword () {
      this.showPassword = !this.showPassword
    },

    asyncFindUsers (query) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (query) {
          this.isLoading = true
          this.axios
            .post(
              `/v1/users/search?limit=${this.perPage}&page=${this.currentPage}&metadata=true&sort[id]=desc&related=wallet`,
              { user: query || '' }
            )
            .then((response) => {
              this.users = response.data.data
              this.isLoading = false
            })
            .catch((err) => {
              this.isLoading = false
              this.users = []
              this.$toastr.e(err?.response?.data?.message, 'Error')
            })
        } else {
          this.users = []
        }
      }, 600)
    },
    async fetchUsers () {
      this.isLoading = true
      try {
        const result = await this.axios.get(
          `/v1/users?limit=${this.perPage}&page=${this.currentPage}`
        )
        this.users = result.data
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    }
  },
  watch: {
    'form.phone' () {
      this.validatePhoneNumber()
    },
    'user.phone' () {
      this.validateUserPhoneNumber()
    }
  }
}
</script>

<style scoped>
.passwordContainer {
  position: relative;
}
.toggleEye {
  position: absolute;
  bottom: 0px;
  top: 10px;
  right: 10px;
}
</style>
